import { Logo } from "../components/nav";

export default function Custom404() {
  return (
    <div className="flex h-full center flex-col w-full pt-16 pb-12">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <a href="/" className="inline-flex">
            <Logo />
          </a>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-base font-semibold text-neutral-200">OOPS!</p>
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-neutral-50 sm:text-5xl">
              Page not found.
            </h1>
            <p className="mt-2 text-base text-neutral-400">
              Sorry, we couldn’t find the page you’re looking for.
            </p>
            <div className="mt-6">
              <a href="/" className="text-base font-medium text-volt">
                Go back home
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </main>
      <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-6 lg:px-8">
        <nav className="flex justify-center space-x-4">
          <a
            href="https://airtable.com/shr0zfvbG5p5IkaFj"
            className="text-sm font-medium text-neutral-300 hover:text-neutral-400"
          >
            Contact Support
          </a>
        </nav>
      </footer>
    </div>
  );
}
